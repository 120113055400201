import { Chat, Message } from '@shared/models/chat.model';

export class WebSocketMessage {
  event: WebSocketEvent;
  data: string;
}

export class WebSocketResponse {
  event: WebSocketEvent;
  data: WebSocketData;
}

export enum WebSocketEvent {
  getActiveUsers = 'getActiveUsers',
  getAllChats = 'getAllChats',
  getManagerNotifications = 'getManagerNotifications',
  getStudentNotifications = 'getStudentNotifications',
  allChats = 'allChats',
  getChatMessages = 'getChatMessages',
  chatMessages = 'chatMessages',
  message = 'message',
  removeMessage = 'removeMessage',
  messageRemoved = 'messageRemoved',
  activeUsers = 'activeUsers',
  managerNotifications = 'managerNotifications',
  studentNotifications = 'studentNotifications',
  lessonActive = 'lessonActive',
  quizQuestionActive = 'quizQuestionActive'
}

export type WebSocketData =
  | WebSocketManagerNotifications
  | WebSocketActiveUsers
  | Chat[]
  | Message
  | Message[]
  | WebSocketStudentNotifications;

export type WebSocketManagerNotifications = {
  chat: number;
  tasksReview: number;
};

export type WebSocketActiveUsers = string[];

export type WebSocketStudentNotifications = {
  activeQuizzes: number;
  activeTasks: number;
  unreadMessages: number;
};

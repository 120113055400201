import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { SubscriptionMeta } from '../../models';
import { CreateInvoice, DiscountData } from '@shared/models/subscription.model';

export const load = createAction('[Subscriptions] Load');

export const loadSuccess = createAction(
  '[Subscriptions] Load success',
  props<{ items: SubscriptionMeta[] }>()
);

export const loadFail = createAction(
  '[Subscriptions] Load fail',
  props<{ error: HttpErrorResponse }>()
);

export const applyPromoCode = createAction(
  '[Subscriptions] Apply promo code',
  props<{ code: string }>()
);

export const applyPromoCodeSuccess = createAction(
  '[Subscriptions] Apply promo code success',
  props<{
    discount: DiscountData;
  }>()
);

export const applyPromoCodeFail = createAction(
  '[Subscriptions] Apply promo code fail',
  props<{
    error: HttpErrorResponse;
  }>()
);

export const createInvoice = createAction(
  '[Subscriptions] Create invoice',
  props<{ invoice: CreateInvoice }>()
);

export const createInvoiceSuccess = createAction('[Subscriptions] Create invoice success');

export const createInvoiceFail = createAction(
  '[Subscriptions] Create invoice fail',
  props<{
    error: HttpErrorResponse;
  }>()
);

export const studentSubscriptionError = createAction('[Subscriptions] Student subscription error');

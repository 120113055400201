import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private origin = this.document.location.origin;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private meta: Meta,
    private title: Title,
    private router: Router
  ) {}

  public setTitle(title: string): void {
    this.title.setTitle(`${title} | Plekaite`);
  }

  public setMetaTags(title = '', description = '', keywords = ''): void {
    if (title.length) this.meta.updateTag({ name: 'title', content: title });

    if (description.length) this.meta.updateTag({ name: 'description', content: description });

    if (keywords.length) this.meta.updateTag({ name: 'keywords', content: keywords });
  }

  public setAlternateLinks(): void {
    const els = this.document.querySelectorAll("link[rel='alternate']");
    if (els?.length) return;
    this.setAlternateLink('', 'en-US');
    this.setAlternateLink('ua', 'uk-UA');
  }

  public manageCanonical(): void {
    const { url } = this.router;
    if (url === '/') {
      this.removeCanonicalLinks();
    }

    if (url === '/ua') {
      this.removeCanonicalLinks();
      this.createCanonicalLink();
    }
  }

  private setAlternateLink(path: string, hreflang: string): void {
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'alternate');
    link.setAttribute('href', `${this.origin}/${path}`);
    link.setAttribute('hreflang', hreflang);
    this.document.head.appendChild(link);
  }

  private createCanonicalLink(): void {
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', this.document.URL);
    this.document.head.appendChild(link);
  }

  private removeCanonicalLinks(): void {
    const els = this.document.querySelectorAll("link[rel='canonical']");
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }
}

export enum Plan {
  free = 'free',
  standard = 'standard',
  pro = 'pro',
  premium = 'premium',
  enterprise = 'enterprise'
}

export class SubscriptionMeta {
  plan: Plan;
  planSize: number;
  price: number;
}

export class Subscription extends SubscriptionMeta {
  isPaid: boolean;
  payDate: string;
  remainsSize: number;
  subscribedDate: string;
  endSubscriptionDate: string;
}

import { createAction, props } from '@ngrx/store';

export const setActiveUsers = createAction(
  '[Notifications] Set active users',
  props<{ activeUsers: string[] }>()
);

export const setManagerNotifications = createAction(
  '[Notifications] Set manager notifications',
  props<{ activeTasks: number; unreadMessages: number }>()
);

export const setStudentNotifications = createAction(
  '[Notifications] Set student notifications',
  props<{
    activeTasks: number;
    activeQuizzes: number;
    unreadMessages: number;
  }>()
);

export const setInitialState = createAction('[Notifications] Set initial state');

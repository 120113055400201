import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import * as actions from '../actions/subscriptions.action';
import * as snackBarActions from '../actions/snack-bar.action';
import { UserService } from '../../services/user.service';

@Injectable()
export class SubscriptionsEffect {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.load),
      mergeMap(() =>
        this.userService.getSubscription().pipe(
          map(items => actions.loadSuccess({ items })),
          catchError((error: HttpErrorResponse) => of(actions.loadFail({ error })))
        )
      )
    )
  );

  applyPromoCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.applyPromoCode),
      switchMap(({ code }) => {
        return this.userService.applyPromoCode(code).pipe(
          map(discount => actions.applyPromoCodeSuccess({ discount })),
          catchError((error: HttpErrorResponse) => of(actions.applyPromoCodeFail({ error })))
        );
      })
    )
  );

  createInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createInvoice),
      switchMap(({ invoice }) => {
        return this.userService.createInvoice(invoice).pipe(
          map(({ invoiceUrl }) => {
            window.open(invoiceUrl, '_blank');
            return actions.createInvoiceSuccess();
          }),
          catchError((error: HttpErrorResponse) => of(actions.createInvoiceFail({ error })))
        );
      })
    )
  );

  errorMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[actions.loadFail, actions.applyPromoCodeFail, actions.createInvoiceFail]),
      map(({ error }) => snackBarActions.errorMessage({ message: error?.error?.message }))
    )
  );

  studentSubscriptionError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.studentSubscriptionError),
      map(() =>
        snackBarActions.infoMessage({
          message:
            'access-to-the-course-is-temporarily-restricted-due-to-an-inactive-teacher-subscription'
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userService: UserService
  ) {}
}

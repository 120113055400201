import { createFeatureSelector, createSelector } from '@ngrx/store';

import { coreStateKey, State } from '@core/store/reducers/core.reducer';
import { User, UserRole } from '../../models';

export const selectCoreState = createFeatureSelector<State>(coreStateKey);

export const selectUserState = createSelector(selectCoreState, coreState => coreState.user);

export const selectUser = createSelector(selectUserState, state => state.item);

export const selectUserName = createSelector(selectUserState, state => state.item?.name);

export const selectUserRole = createSelector(selectUserState, state => state.item?.role);

export const selectIsManager = createSelector(
  selectUserRole,
  (role: UserRole) => role === UserRole.manager
);

export const selectIsTeamMember = createSelector(
  selectUserRole,
  (role: UserRole) => role === UserRole.teamMember
);

export const selectTeamMemberPermissions = createSelector(
  selectUser,
  (user: User) => user.permissions
);

export const selectSubscription = createSelector(
  selectUserState,
  state => state.item?.subscription
);

export const selectSubscriptionPlan = createSelector(
  selectUserState,
  state => state.item?.subscription?.plan
);

export const selectSubscriptionPlanSize = createSelector(
  selectUserState,
  state => state.item?.subscription?.planSize
);

export const selectSubscribedDate = createSelector(
  selectUserState,
  state => state.item?.subscription?.subscribedDate
);

export const selectEndSubscriptionDate = createSelector(
  selectUserState,
  state => state.item?.subscription?.endSubscriptionDate
);

export const selectSubscriptionIsPaid = createSelector(
  selectUserState,
  state => state.item?.subscription?.isPaid
);

export const selectRemainsSize = createSelector(
  selectUserState,
  state => state.item?.subscription?.remainsSize
);

export const selectLoading = createSelector(selectUserState, state => state.loading);

export const selectLoaded = createSelector(selectUserState, state => state.loaded);

export const selectInvitation = createSelector(selectUser, (user: User) => user.invitation);

import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { UpdatePassword, UpdateProfile, User } from '../../models';
import { Lang } from '@shared/models/lang.model';

export const load = createAction('[Core] Load user');

export const loadSuccess = createAction('[Core] Load user success', props<{ user: User }>());

export const loadFail = createAction('[Core] Load user fail', props<{ message: string }>());

export const update = createAction('[Core] Update user', props<{ user: UpdateProfile }>());

export const updateSuccess = createAction('[Core] Update user success', props<{ user: User }>());

export const updateFail = createAction('[Core] Update user fail', props<{ message: string }>());

export const remove = createAction('[Core] Remove user', props<{ password: string }>());

export const removeSuccess = createAction('[Core] Remove user success');

export const removeFail = createAction(
  '[Core] Remove user fail',
  props<{ error: HttpErrorResponse }>()
);

export const updatePassword = createAction(
  '[Core] Update password',
  props<{ updatePassword: UpdatePassword }>()
);

export const updatePasswordSuccess = createAction('[Core] Update password success');

export const updatePasswordFail = createAction(
  '[Core] Update password fail',
  props<{ error: HttpErrorResponse }>()
);

export const updateUserLang = createAction('[Core] Update user lang', props<{ lang: Lang }>());

export const updateUserLangSuccess = createAction(
  '[Core] Update user lang success',
  props<{ user: User }>()
);

export const updateUserLangFail = createAction(
  '[Core] Update user lang fail',
  props<{ error: HttpErrorResponse }>()
);

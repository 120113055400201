export class SnackBarData {
  message: string;
  type: SnackBarType;
}

export enum SnackBarType {
  info = 'info',
  success = 'success',
  error = 'error'
}

export enum SnackBarIcons {
  info = 'info',
  success = 'check-circle',
  error = 'exclaimation-circle'
}

import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideEffects } from '@ngrx/effects';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';

import { routes } from './app.routes';
import { coreReducer, coreStateKey } from '@core/store/reducers/core.reducer';
import { CoreEffect } from '@core/store/effects/core.effect';
import { UserEffect } from '@core/store/effects/user.effect';
import { SubscriptionsEffect } from '@core/store/effects/subscriptions.effect';
import { SnackBarEffect } from '@core/store/effects/snack-bar.effect';
import { TokenInterceptor } from '@core/interceptors/token.interceptor';
import { environment } from '../environments/environment';

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideStore(),
    provideState({
      name: coreStateKey,
      reducer: coreReducer
    }),
    provideEffects(CoreEffect, UserEffect, SubscriptionsEffect, SnackBarEffect),
    provideStoreDevtools({
      logOnly: !environment.production,
      connectInZone: true
    }),
    provideTranslateService({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      useDefaultLang: false
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
};

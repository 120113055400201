import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { SubscriptionMeta, UpdatePassword, UpdateProfile, User } from '../models';
import {
  CreateInvoice,
  CreateInvoiceResponse,
  DiscountData
} from '@shared/models/subscription.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  public getUser(): Observable<User> {
    return this.httpClient.get<User>(`${environment.apiUrl}/user`);
  }

  public updateUser(user: UpdateProfile): Observable<User> {
    const fd = new FormData();
    fd.append('name', user.name);
    fd.append('phone', user.phone);
    fd.append('gender', user.gender);
    fd.append('language', user.language);
    if (user.languageEmails) {
      fd.append('languageEmails', user.languageEmails);
    }
    fd.append('description', user.description);
    if (user.photoFile && user.photoFile instanceof File) {
      fd.append('photoFile', user.photoFile, user.photoFile.name);
    } else {
      fd.append('photo', user.photo);
    }
    if (user.notifications) {
      fd.append('notifications', JSON.stringify(user.notifications));
    }
    return this.httpClient.patch<User>(`${environment.apiUrl}/user`, fd);
  }

  public updatePassword(updatePassword: UpdatePassword): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiUrl}/user/update-password`, updatePassword);
  }

  public removeUser(password: string): Observable<void> {
    return this.httpClient.delete<void>(`${environment.apiUrl}/user/delete?password=${password}`);
  }

  public getSubscription(): Observable<SubscriptionMeta[]> {
    return this.httpClient.get<SubscriptionMeta[]>(`${environment.apiUrl}/subscription`);
  }

  public applyPromoCode(code: string): Observable<DiscountData> {
    return this.httpClient.get<DiscountData>(
      `${environment.apiUrl}/subscription/promo-code?code=${code}`
    );
  }

  public createInvoice(data: CreateInvoice): Observable<CreateInvoiceResponse> {
    return this.httpClient.post<CreateInvoiceResponse>(
      `${environment.apiUrl}/payment/create-invoice`,
      data
    );
  }
}
